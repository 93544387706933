import React, { useState } from "react";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import { getMyBranch } from "../User/actions";
import {
  GET_STORES,
  GET_DELIVERYBOY,
  GET_PRODUCTS,
  LOADING,
  LOADED,
  GET_DB,
  GET_BRANCHORDER,
  GET_EXECUTIVES,
  GET_BANNER,
  SET_DBANALYSIS,
  GET_PROMOCODES,
  GET_PENDING_PAYMENTS,
  GET_SUBSCRIPTION,
} from "./constants";

// Add Restaurant
export const addStore = (data) => async (dispatch, getState) => {
  dispatch({ type: LOADING });
  const myBranch = getState().auth.branch;
  var success = false;
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("branch", myBranch._id);
  fd.append("radius", data.radius);

  if (myBranch.branchType === "listing") {
    fd.append("subscription", data.subscription);
    fd.append("deliveryChargeKm", data.deliveryChargeKm);
    fd.append("minDeliveryDistance", data.minDeliveryDistance);
    fd.append("minDeliveryCharge", data.minDeliveryCharge);
    fd.append("minOrderAmt", data.minOrderAmt);
    fd.append("minSurcharge", data.minSurcharge);
  }

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.longitude);
  fd.append("location.coordinates", data.latitude);
  fd.append("location.landmark", data.landmark);

  fd.append("password", data.password);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);
  fd.append("commission", data.commission);
  fd.append("dCommission", data.dCommission);
  fd.append("sortOrder", data.sortOrder);
  data.razorpayXid && fd.append("razorpayXid", data.razorpayXid);
  data.avgCookingTime && fd.append("avgCookingTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  data.gst && fd.append("gst", data.gst);
  data.fssai && fd.append("fssai", data.fssai);
  data.quickDelivery && fd.append("quickDelivery", data.quickDelivery);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  data.ownerContact && fd.append("ownerContact", data.ownerContact);
  fd.append("type", "restaurant");
  fd.append("filename", "final");
  fd.append("folder", "restaurants");
  fd.append("image", data.storeBg);
  fd.append("image", data.storeLogo);
  try {
    const res = await api.post("/restaurant", fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Created Successfully", "success"));
    } else {
      dispatch({ type: LOADED });
    }
    success = true;
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
  return success;
};

// Edit Restaurant
export const editStore = (data) => async (dispatch, getState) => {
  let fd = new FormData();
  const myBranch = getState().auth.branch;

  fd.append("name", data.name);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.coordinates[0]);
  fd.append("location.coordinates", data.coordinates[1]);
  fd.append("location.landmark", data.landmark);
  fd.append("radius", data.radius);
  if (myBranch.branchType === "listing") {
    fd.append("subscription", data.subscription);
    fd.append("deliveryChargeKm", data.deliveryChargeKm);
    fd.append("minDeliveryDistance", data.minDeliveryDistance);
    fd.append("minDeliveryCharge", data.minDeliveryCharge);
    fd.append("minOrderAmt", data.minOrderAmt);
    fd.append("minSurcharge", data.minSurcharge);
  }
  fd.append("hasGst", data.hasGst);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);
  fd.append("commission", data.commission);
  fd.append("dCommission", data.dCommission);
  fd.append("sortOrder", data.sortOrder);
  fd.append("quickDelivery", data.quickDelivery);
  data.razorpayXid && fd.append("razorpayXid", data.razorpayXid);
  data.password && fd.append("password", data.password);
  data.avgCookingTime && fd.append("avgCookingTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  fd.append("gst", data.gst);
  fd.append("fssai", data.fssai);
  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  data.ownerContact && fd.append("ownerContact", data.ownerContact);

  if (data.editLogo && data.editBg) {
    fd.append("edit", "all");
    fd.append("filename", "final");
    fd.append("folder", "restaurants");
    fd.append("image", data.storeBg);
    fd.append("image", data.storeLogo);
  } else if (data.editLogo) {
    fd.append("edit", "logo");
    fd.append("filename", "final");
    fd.append("folder", "restaurants");
    fd.append("image", data.storeLogo);
  } else if (data.editBg) {
    fd.append("edit", "bg");
    fd.append("filename", "final");
    fd.append("folder", "restaurants");
    fd.append("image", data.storeBg);
  }

  try {
    const res = await api.put(`/restaurant/${data._id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Updated Successfully", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// force close restaurant
export const forceClose = (id, data) => async (dispatch) => {
  let fd = new FormData();

  fd.append("image", "");
  fd.append("force", data);
  try {
    const res = await api.put(`/restaurant/${id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant status updated", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// force close restaurant
export const setStoreBusy = (id, data) => async (dispatch) => {
  let fd = new FormData();

  fd.append("image", "");
  fd.append("storeBusy", data);
  try {
    const res = await api.put(`/restaurant/${id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant data updated", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// set surcharge
export const setSurcharge = (id, data) => async (dispatch) => {
  let fd = new FormData();

  fd.append("image", "");
  fd.append("surchargeApplicable", data);
  try {
    const res = await api.put(`/restaurant/${id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant data updated", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//get all stores
export const getStores = () => async (dispatch, getState) => {
  try {
    const branch =
      getState().auth.user.role === "branch"
        ? getState().auth.branch._id
        : getState().auth.executive?.branch?._id;

    if (branch) {
      const res = await api.get(
        `/restaurant?branch=${branch}&limit=1000&sort=sortOrder`
      );

      dispatch({
        type: GET_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

//get stores of a branch
export const getBranchStores = (branch) => async (dispatch) => {
  try {
    if (branch) {
      const res = await api.get(
        `/restaurant?branch=${branch}&limit=1000&sort=sortOrder`
      );

      dispatch({
        type: GET_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Delete Restaurants
export const deleteStore = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/restaurant/${id}`);
    if (res.data.success) {
      dispatch(setAlert("Restaurant Deleted", "danger"));
      dispatch(getStores());
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    console.log(err);
  }
};

// Get Branch Todays / Completed / Cancelled  Orders
export const getBranchOrders = () => async (dispatch) => {
  try {
    const res = await api.get("order/branch/status?type=today");

    dispatch({
      type: GET_BRANCHORDER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

//add DELIERVY BOY
export const addDeliveryBoy = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("mobile", data.mobile);
  fd.append("password", data.password);
  fd.append("filename", "kannur");
  fd.append("folder", "deliveryboy");
  fd.append("image", data.profile);
  fd.append("image", data.drivingLicense);

  try {
    const res = await api.post("/delivery", fd);
    if (res.data.success) {
      dispatch(setAlert("Delivery boy added", "success"));
      dispatch(getDeliveryBoy());
    } else {
      dispatch(setAlert(res.data.message, "info"));
    }
  } catch (error) {
    console.log(error);
  }
};

//get all DELIVERY BOYS
export const getDeliveryBoy = () => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  try {
    const res = await api.get(`/delivery?branch=${myBranchId}`);
    dispatch({
      type: GET_DELIVERYBOY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// Delete Delivery Boy
export const deleteDeliveryboys = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/delivery/${id}`);
    dispatch(getDeliveryBoy());
    dispatch(setAlert("Delivery boy Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Get products
export const getProducts =
  (searchString, category) => async (dispatch, getState) => {
    dispatch({ type: LOADING });
    const storeId = getState().branch.currentStore;

    const data = {
      type: "name",
      value: searchString ? searchString : "",
      limit: 800,
      restaurant: storeId,
      category: category === "all" ? "" : category,
    };

    try {
      const res = await api.post(`/product/search`, data);
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

// Add Executives (Delivery, Order, Offline)
export const addExecutive = (data, myBranchId) => async (dispatch) => {
  const fData = {
    username: data.username,
    name: data.name,
    type: data.type,
    password: data.password,
  };
  try {
    const res = await api.post(`/executive?type=${fData.type}&branch`, fData);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch(getExecutives(myBranchId));
    }
  } catch (err) {
    if (err.response.data.error === "Duplicate field value entered") {
      dispatch(setAlert("Username already exist", "danger"));
    }
  }
};

//Get all Executives
export const getExecutives = () => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  try {
    const res = await api.get(`/executive?branch=${myBranchId}`);

    dispatch({
      type: GET_EXECUTIVES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(
      `/product?restaurantId=${store}&productId=${id}`
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Edit Product
export const editProduct =
  (productId, data, type) => async (dispatch, getState) => {
    const store = getState().branch.currentStore;
    try {
      const res = await api.put(
        `/product?restaurantId=${store}&productId=${productId}`,
        data
      );
      dispatch(getProducts());
      dispatch(setAlert("Product Updated", "success"));
    } catch (err) {
      console.log(err);
    }
  };

// Add Product
export const addProduct = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });
  let fd = new FormData();

  if (data.rating) fd.append("rating", data.rating);
  if (data.avgCookingTime) fd.append("avgCookingTime", data.avgCookingTime);

  fd.append("filename", data.name);
  fd.append("folder", "products");
  fd.append("name", data.name);
  data.category && fd.append("category", data.category);
  fd.append("type", data.type);
  data.price && fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  if (data.packingCharge) fd.append("packingCharge", data.packingCharge);
  if (data.offerPrice) fd.append("offerPrice", data.offerPrice);
  if (data.specialTag) fd.append("specialTag", data.specialTag);
  fd.append("exclusive", data.exclusive === "true" ? true : false);
  fd.append("whakaaroPrice", data.whakaaroPrice);
  fd.append("description", data.description);
  fd.append("image", data.image);

  try {
    const res = await api.post(`/product?restaurantId=${store}`, fd);
    dispatch(getProducts());
    dispatch(setAlert("Product Added", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Edit Product
export const editProductData = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("rating", data.rating);
  fd.append("avgCookingTime", data.avgCookingTime);

  fd.append("name", data.name);
  fd.append("category", data.category);
  fd.append("type", data.type);
  data.price && fd.append("price", data.price);
  fd.append("specialTag", data.specialTag);
  data.packingCharge === "" || data.packingCharge === undefined
    ? fd.append("packingCharge", 0)
    : fd.append("packingCharge", data.packingCharge);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  (data.offerPrice != null || data.offerPrice != undefined) &&
    fd.append("offerPrice", data.offerPrice);
  fd.append("whakaaroPrice", data.whakaaroPrice);
  fd.append("description", data.description);
  fd.append("exclusive", data.exclusive === "true" ? true : false);
  if (data.image) {
    fd.append("filename", data.name);
    fd.append("folder", "products");
    fd.append("image", data.image);
  }

  try {
    const res = await api.put(
      `/product?restaurantId=${store}&productId=${data.id}`,
      fd
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Updated", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//Add Clickable Banners
export const addBanner = (image, storeId) => async (dispatch) => {
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("filename", Date.now().toString());
  fd.append("folder", "banners");
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  fd.append("image", image);
  try {
    const res = await api.post("/branch/banner", fd);
    if (res.data.success) {
      dispatch(setAlert("Banner Added", "success"));
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
    } else {
      dispatch(setAlert(res.data.data, "danger"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    console.log(err);
  }
};

// delete banner
export const deleteBranchBanner = (bannerID) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/banner?bannerId=${bannerID}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Banner Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

//add Special Messeges
export const addSpecialMessage = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("title", data.title);
  fd.append("description", data.description);
  fd.append("imageType", data.imageType);
  fd.append("filename", "in-demand");
  fd.append("folder", "icon");
  fd.append("icon", data.icon);

  try {
    const res = await api.post("/branch/message", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Special Message Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete Special message
export const deleteMessage = (id, active) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/message?id=${id}&active=${active}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Message Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Set active message
export const activateMessage = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/branch/message?id=${id}&visible=${status}`);
    dispatch(getMyBranch());
    dispatch(
      setAlert(`Message ${status ? "Activated" : "Deactivated"}`, "success")
    );
  } catch (err) {
    console.log(err);
  }
};

//add Popup
export const addPopup = (image, storeId) => async (dispatch) => {
  let fd = new FormData();
  fd.append("filename", "offer");
  fd.append("folder", "popup");
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  fd.append("image", image);
  try {
    const res = await api.post("/branch/popup", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Banner Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete popup
export const deletePopup = () => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/popup`);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Send notification
export const sendNotification = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/notify`, data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add offer
export const addOffer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("subtitle", data.subtitle);
    fd.append("primary", data.primary);
    fd.append("secondary", data.secondary);
    fd.append("type", data.type);
    fd.append("link", JSON.stringify(data.link));
    fd.append("filename", "offer1");
    fd.append("folder", "offers");
    fd.append("image", data.image);
    fd.append("image", data.icon);

    const res = await api.post(`/branch/offer`, fd);
    if (res.data.success) {
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
      dispatch(setAlert("Offer Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Offer
export const deleteOffer = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/offer?offerId=${id}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Offer Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Change restaurant status
export const changeStoreStatus = (status, id) => async (dispatch) => {
  try {
    const res = await api.put(
      `restaurant/status?type=branch&status=${status}`,
      { restaurant: id }
    );
    dispatch(getStores());
    dispatch(setAlert("Store Status Updated", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Add Bonus Types
export const addBonusTypes = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/bonus`, data);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Bonus Types
export const deleteBonusTypes = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/bonus?id=${id}`);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy analysis
export const getDBAnalysis = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(`/delivery/analysis?id=${id}&date=${date}`);
    if (res.data.success) {
      dispatch({ type: SET_DBANALYSIS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy todays orders
export const getDBOrders = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(
      `/order/deliveryboy?deliveryboy=${id}&date=${date}`
    );
    if (res.data.success) {
      dispatch({
        type: GET_BRANCHORDER,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Delivery boy settlement
export const settleDelivery = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/settlement?type=delivery`, data);
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// get promocodes
export const getPromocodes = () => async (dispatch, getState) => {
  const branch = getState().auth.branch._id;
  try {
    const res = await api.get(`/promocode?branch=${branch}`);
    if (res.data.success) {
      dispatch({
        type: GET_PROMOCODES,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Add promocodes
export const addPromocode = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/promocode`, data);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete promocodes
export const deletePromo = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/promocode?id=${id}`);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Deleted", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit promocodes
export const editPromo = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/promocode?id=${id}`, { status });
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Updated", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add category to store
export const addStoreCategory = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.post(`/restaurant/category?id=${store}`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Added to ${res.data.data.name}`, "success"));
      dispatch(getStores());
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete category from store
export const deleteStoreCategory = (catId) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(
      `/restaurant/category?id=${store}&categoryId=${catId}`
    );
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted`, "success"));
      dispatch(getStores());
    }
  } catch (err) {
    console.log(err);
  }
};

// Change category status from store
export const StoreCategoryStatus =
  (catId, data) => async (dispatch, getState) => {
    try {
      const res = await api.put(`/restaurant/category?id=${catId}`, data);
      if (res.data.success) {
        dispatch(setAlert(`Category Status Updated`, "success"));
        dispatch(getStores());
      }
    } catch (err) {
      console.log(err);
    }
  };

// get Pending orders
export const getPendingPayments = () => async (dispatch) => {
  try {
    const res = await api.get(`/order`);
    if (res.data.success) {
      dispatch({
        type: GET_PENDING_PAYMENTS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// add credit to customer
export const addCredit = (data) => async (dispatch) => {
  try {
    const res = await api.put(`/customer`, data);
    if (res.data.success) {
      dispatch(setAlert(`Credit Updated`, "success"));
      dispatch(getPendingPayments());
    }
  } catch (err) {
    console.log(err);
  }
};

// add subscription
export const addSubscription = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/subscription`, data);
    if (res.data.success) {
      dispatch(setAlert(`Subscription Added`, "success"));
      dispatch(getSubscription());
    }
  } catch (err) {
    console.log(err);
  }
};

// edit subscription
export const editSubscription = (data) => async (dispatch) => {
  try {
    const res = await api.put(`/subscription/${data.id}`, {
      dueDate: data.date,
    });
    if (res.data.success) {
      dispatch(setAlert(`Subscription updated`, "success"));
      dispatch(getSubscription());
    }
  } catch (err) {
    console.log(err);
  }
};

// Get subscription
export const getSubscription = (filter) => async (dispatch, getState) => {
  const branch = getState().auth.branch._id;
  try {
    const res = await api.get(
      filter
        ? `/subscription?branch=${branch}&${filter}`
        : `/subscription?branch=${branch}`
    );
    dispatch({ type: GET_SUBSCRIPTION, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

// Delete Product Image
export const deleteProductImage = (productId) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("deleteImage", true);

  try {
    const res = await api.put(
      `/product?vendorId=${store}&productId=${productId}`,
      fd
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Image Deleted", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};
